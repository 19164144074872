import { query, collection, updateDoc, doc, where, arrayUnion, deleteDoc, onSnapshot, Unsubscribe, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../config/firebase-config";
import { MAIL_EMAIL } from "../functions/mailMessages";
import AppStore from "../stores/AppStore";
import AppApi, { apiPathProjectLevel } from "./AppApi";
import { IMilestoneIssue } from "../models/MilestoneIssue";

export default class MilestoneIssueApi {
    constructor(private api: AppApi, private store: AppStore) { }

    private milestoneIssuePath = (projectId: string) => {
        if (!projectId) return null;
        return apiPathProjectLevel(projectId, "issues");
    }

    async createIssue(issue: IMilestoneIssue) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        const itemRef = doc(collection(db, path))
        issue.id = itemRef.id;
        try {
            await setDoc(itemRef, issue, { merge: true, })
        } catch (error) {
        }
    }

    async updateIssue(issue: IMilestoneIssue) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        try {
            await updateDoc(doc(db, path, issue.id), {
                ...issue,
            });
            this.store.milestoneIssue.load([issue]);
        } catch (error) {
            console.log(error);

        }
    }

    async deleteIssue(issue: IMilestoneIssue) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        try {
            await deleteDoc(doc(db, path, issue.id));
            this.store.milestoneIssue.remove(issue.id);
        } catch (error) { }
    }

    async getAllIssue(projectId: string) {

        this.store.milestoneIssue.removeAll();

        const path = this.milestoneIssuePath(projectId);
        if (!path) return;

        const $query = query(collection(db, path));

        return await new Promise<Unsubscribe>((resolve, reject) => {
            const unsubscribe = onSnapshot($query, (querySnapshot) => {
                const milestoneIssue: IMilestoneIssue[] = [];
                querySnapshot.forEach((doc) => {
                    milestoneIssue.push({ id: doc.id, ...doc.data() } as IMilestoneIssue);
                });
                this.store.milestoneIssue.load(milestoneIssue);
                resolve(unsubscribe);
            }, (error) => {
                reject();
            });
        });

    }

    async getUserIssue(projectId: string, uid: string) {

        this.store.milestoneIssue.removeAll();

        const path = this.milestoneIssuePath(projectId);
        if (!path) return;

        const $query = query(collection(db, path), where("uid", "==", uid));

        const unsubscribe = onSnapshot($query, (querySnapshot) => {
            const milestoneIssue: IMilestoneIssue[] = [];
            querySnapshot.forEach((doc) => {
                milestoneIssue.push({ id: doc.id, ...doc.data() } as IMilestoneIssue);
            });
            this.store.milestoneIssue.load(milestoneIssue);
        }
        );

        return unsubscribe;
    }

    async getIssuesByUid(projectId: string, uid: string) {

        this.store.milestoneIssue.removeAll();

        const path = this.milestoneIssuePath(projectId);
        if (!path) return;

        const $query = query(collection(db, path), where("usersId", "array-contains", uid));

        const unsubscribe = onSnapshot($query, (querySnapshot) => {
            const milestoneIssue: IMilestoneIssue[] = [];
            querySnapshot.forEach((doc) => {
                milestoneIssue.push({ id: doc.id, ...doc.data() } as IMilestoneIssue);
            });
            this.store.milestoneIssue.load(milestoneIssue);
        }
        );

        return unsubscribe;
    }



    async addIssueMember(issue: IMilestoneIssue, userId: string) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        const taskRef = doc(db, path, issue.id);
        try {
            await updateDoc(taskRef, {
                usersId: arrayUnion(userId)
            });
        } catch (error) { }
    }

    async removeIssueMember(issue: IMilestoneIssue) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        try {
            await updateDoc(doc(db, path, issue.id), {
                ...issue,
            });
        } catch (error) { }
    }


    async issueComment(issue: IMilestoneIssue, comment: string) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        const taskRef = doc(db, path, issue.id);
        try {
            await updateDoc(taskRef, {
                comments: arrayUnion(comment)
            });
        }
        catch (error) { }
    }


    async attachTaskFiles(issue: IMilestoneIssue, value: { name: string; link: string; }) {

        const path = this.milestoneIssuePath(issue.projectId);
        if (!path) return;

        const taskRef = doc(db, path, issue.id);

        this.store.projectStatus.setStatus("start");
        await updateDoc(taskRef, {
            files: arrayUnion(value)
        });
    }

    async uploadIssueFile(issue: IMilestoneIssue, file: File) {

        const filePath = `milestoneIssue/${file.name}`;
        const uploadTask = uploadBytesResumable(ref(storage, filePath), file);

        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.store.projectStatus.setStatus(`${Math.round(progress)}`);
            switch (snapshot.state) {
                case 'paused':
                    break;
                case 'running':
                    break;
            }
        }, (error) => { }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const value = { name: file.name, link: downloadURL }
                this.attachTaskFiles(issue, value);
            });
        }
        );
    }

    async uploadMilestoneAttachmentFile(file: File, to: string, message: string) {

        const filePath = `milestoneIssue/${file.name}`;
        const uploadTask = uploadBytesResumable(ref(storage, filePath), file)

        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.store.projectStatus.setProgress(Math.round(progress));
        }, (error) => { }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                const msg = message.concat(`</br><a href="${downloadURL}" download>${file.name}</a>`);
                await this.api.mail.sendMail(
                    [to],
                    MAIL_EMAIL,
                    "Issue Resolved",
                    msg
                )
                window.alert("Email successfully sent")
            });
        }
        );
    }
}



