import { useEffect, useState } from "react";
import useTitle from "../../shared/hooks/useTitle";
import Toolbar from "../shared/components/toolbar/Toolbar";
import SettingsTabs from "./SettingsTabs";
import MODAL_NAMES from "../dialogs/ModalName";
import showModalFromId from "../../shared/functions/ModalShow";
import Dropdown from "../../shared/components/dropdown/Dropdown";
import DepartmentList from "./DepartmentList";
import UserList from "./UserList";
import BusinessUnitList from "./BusinessUnitList";
import UserModal from "../dialogs/user/UserModal";
import Modal from "../../shared/components/Modal";
import BusinessUnitModal from "../dialogs/business-unit/BusinessUnitModal";
import DepartmentModal from "../dialogs/department/DepartmentModal";
import { useAppContext } from "../../shared/functions/Context";
import useBackButton from "../../shared/hooks/useBack";
import { observer } from "mobx-react-lite";
import { LoadingEllipsis } from "../../shared/components/loading/Loading";
import ErrorBoundary from "../../shared/components/error-boundary/ErrorBoundary";
import "./AdminSettings.scss";

const AdminSettings = observer(() => {
  const { api, store } = useAppContext();
  const [selectedTab, setselectedTab] = useState("user-tab");
  const [loading, setLoading] = useState(false);
  useTitle("Admin Settings");
  useBackButton();

  const handleNewUser = () => {
    showModalFromId(MODAL_NAMES.ADMIN.USER_MODAL);
  };



  const handleNewDepartment = () => {
    store.department.clearSelected(); // clear selected department
    showModalFromId(MODAL_NAMES.ADMIN.DEPARTMENT_MODAL); // show modal
  };



  const handleNewBusinessUnit = () => {
    store.businessUnit.clearSelected(); // clear selected business unit
    showModalFromId(MODAL_NAMES.ADMIN.BUSINESS_UNIT_MODAL); // show modal
  };



  useEffect(() => {
    // load data from db
    const loadAll = async () => {
      setLoading(true); // start loading
      try {
        await api.user.getAll();
        await api.department.getAll();
        await api.businessUnit.getAll();
      } catch (error) {
      }
      setLoading(false); // stop loading
    };
    loadAll();
  }, [api.businessUnit, api.department,]);

  return (
    <ErrorBoundary>
      <div className="admin-settings uk-section uk-section-small">
        <div className="uk-container uk-container-xlarge">
          <div className="sticky-top">
            <ErrorBoundary>
              <Toolbar
                leftControls={
                  <SettingsTabs
                    selectedTab={selectedTab}
                    setselectedTab={setselectedTab}
                  />
                }
                rightControls={
                  <div className="uk-inline">
                    {selectedTab === "user-tab" && (
                      <button
                        className="btn btn-primary"
                        onClick={handleNewUser}
                      >
                        <span data-uk-icon="icon: plus; ratio:.8"></span>
                        New User
                      </button>
                    )}

                    {selectedTab === "department-tab" && (
                      <button
                        className="btn btn-primary"
                        onClick={handleNewDepartment}
                      >
                        <span data-uk-icon="icon: plus; ratio:.8"></span>
                        New Department
                      </button>
                    )}
                    {selectedTab === "business-unit-tab" && (
                      <button
                        className="btn btn-primary"
                        onClick={handleNewBusinessUnit}
                      >
                        <span data-uk-icon="icon: plus; ratio:.8"></span>
                        New Business Unit
                      </button>
                    )}
                  </div>
                }
              />
            </ErrorBoundary>
          </div>

          <ErrorBoundary>
            {!loading && (
              <div className="uk-margin">
                {selectedTab === "user-tab" && <UserList />}
                {selectedTab === "department-tab" && <DepartmentList />}
                {selectedTab === "business-unit-tab" && <BusinessUnitList />}

              </div>
            )}
          </ErrorBoundary>

          {/* Loading */}
          <ErrorBoundary>{loading && <LoadingEllipsis />}</ErrorBoundary>
        </div>
      </div>

      {/* Modals */}
      <ErrorBoundary>
        <Modal modalId={MODAL_NAMES.ADMIN.USER_MODAL}>
          <UserModal />
        </Modal>
        <Modal modalId={MODAL_NAMES.ADMIN.DEPARTMENT_MODAL}>
          <DepartmentModal />
        </Modal>
        <Modal modalId={MODAL_NAMES.ADMIN.BUSINESS_UNIT_MODAL}>
          <BusinessUnitModal />
        </Modal>

      </ErrorBoundary>
    </ErrorBoundary>
  );
});

export default AdminSettings;
