const MODAL_NAMES = {

    
  ADMIN: {
    USER_MODAL: "user-modal",
    ROLE_MODAL: "role-modal",
    SCORECARD_BATCH_MODAL: "scorecard-batch-modal",
    DEPARTMENT_MODAL: "department-modal",
    BUSINESS_UNIT_MODAL: "business-unit-modal",
    STRATEGIC_THEME_MODAL: "strategic-theme-modal",
  },
  
  PROJECTS: {
    CREATE_PROJECT: "newProject-Modal",
    CREATE_TASK: "newTask-Modal",
    CREATE_MILESTONE: "newMilestone-Modal",
    CREATE_RISK: "newRisk-modal",
    CREATE_PORTFOLIO: "newPortfolio-modal",
    VIEW_TASK: "view-Task-Modal",
    VIEW_RISK: "view-Risk-Modal",
    ADD_USER: "add-user-modal",
    PROJECT_FILES: "project-files-modal",
    ATTACH_MILESTONE_BILL: "attach-bill-modal",
    MILESTONE_ISSUE_MODAL: "milestone-issue-modal",
  },
  FEEDBACK: {
    CREATE: "feedback-create-modal",
  },

  GENERAL_TASKS: {
    CREATE_GENERAL_TASK: "general-tasks-modal",
    VIEW_GENERAL_TASKS:"view-general-task-modal"
  },
};

export default MODAL_NAMES;
