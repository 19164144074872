import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Loading from "./shared/components/loading/Loading";
import SnackbarManager from "./shared/components/snackbar/SnackbarManager";
import { AppContext } from "./shared/functions/Context";
import AdminSettings from "./logged-in/admin-settings/AdminSettings";
import PrivateRoute from "./shared/functions/PrivateRoute";
import LoggedOut from "./logged-out/LoggedOut";
import { MainApp } from "./shared/models/App";
import "./App.scss";
import PrimeReact from 'primereact/api';
import PortfolioProjects from "./logged-in/project-management/portfolioProjects.page";
import Project from "./logged-in/project-management/project.page";
import Statistics from "./logged-in/project-management/statistics.page";
import Tasks from "./logged-in/project-management/tasks.page";
import UserProjects from "./logged-in/project-management/usersProjects.page";
import PortfolioPage from "./logged-in/project-management/portfolio.page";

PrimeReact.ripple = true;

// TO IMPROVE, have issues under a a specific milestone, consider drug and drop for tasks, indicate a hover for tasks to open a pop up, 
// Have a A open risk button on the risks accordion. 

const LoggedIn = lazy(() => import("./logged-in/LoggedIn"));

const PrivateLoggedIn = () => (
  <PrivateRoute>
    <Suspense fallback={<Loading fullHeight={true} />}>
      <LoggedIn />
    </Suspense>
  </PrivateRoute>
);


const HR_USER_ROUTES = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="c" element={<PrivateLoggedIn />}>
          <Route path="projects" element={<UserProjects />} />
          <Route path="projects/:id" element={<PortfolioProjects />} />
          <Route path="project/:projectId" element={<Project />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="settings" element={<AdminSettings />} />
          <Route path="*" element={<Navigate to="statistics" />} />
        </Route>
        <Route path="/" element={<LoggedOut />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

const App = () => {
  const app = new MainApp();
  const { store, api, ui } = app;

  return (
    <div className="app">
      <AppContext.Provider value={{ store, api, ui }}>
        <HR_USER_ROUTES />
        <SnackbarManager />
      </AppContext.Provider>
    </div>
  );
};
export default App;
