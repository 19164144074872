import {
  query,
  collection,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
} from "@firebase/firestore";
import { Unsubscribe, getDoc, getDocs } from "firebase/firestore";
import { auth, authWorker, db } from "../config/firebase-config";
import { IUser } from "../models/User";
import AppStore from "../stores/AppStore";
import AppApi from "./AppApi";
import { User, createUserWithEmailAndPassword, deleteUser, sendPasswordResetEmail, signOut } from "firebase/auth";
import path from "path";

export default class UserApi {
  path: string | null = null;

  constructor(private api: AppApi, private store: AppStore) { }

  getPath() {
    return "users";
  }

  async getAll() {
    // get the db path
    const path = this.getPath();
    if (!path) return;

    // remove all items from store
    this.store.user.removeAll();

    // create the query
    const $query = query(collection(db, path));
    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            const user = { uid: doc.id, ...doc.data() } as IUser;

            const DEV_MODE =
              !process.env.NODE_ENV || process.env.NODE_ENV === "development";
            if (DEV_MODE) items.push(user);
            else if (!user.devUser) items.push(user);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          reject();
        }
      );
    });
  }

  async delete(user: IUser) {
    const path = this.getPath();
    if (!path) return;

    const docRef = doc(db, path, user.uid);
    await deleteDoc(docRef);
    this.store.user.remove(user.uid)
  }

  async create(user: IUser) {

    const path = this.getPath();
    if (!path) return;

    const { email, password = `123456///` } = user;
    const userCredential = await createUserWithEmailAndPassword(authWorker, email, password).catch((error) => {
      return null;
    });

    if (userCredential) {
      user.uid = userCredential.user.uid;
      user.password = "";
      await setDoc(doc(db, path, user.uid), user);
      this.store.user.load([user])
      await signOut(authWorker);
    }
    return user;
  }

  async update(item: IUser) {
    const path = this.getPath();
    if (!path) return;

    try {
      await updateDoc(doc(db, path, item.uid), {
        ...item,
      });
      this.store.user.load([item]);
    } catch (error) {
    }
  }

  async getAllSubordinates(uid: string) {
    // get the db path
    const path = this.getPath();
    if (!path) return;

    // remove all items from store
    this.store.user.removeAll();

    // create the query
    const $query = query(collection(db, path), where("supervisor", "==", uid));
    // new promise
    return await new Promise<Unsubscribe>((resolve, reject) => {
      // on snapshot
      const unsubscribe = onSnapshot(
        $query,
        // onNext
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        // onError
        (error) => {
          // console.log(error);

          reject(error);
        }
      );
    });
  }

  async getByDepartment(departmentId: string) {
    const path = this.getPath();
    if (!path) return;
    this.store.user.removeAll();

    const $query = query(
      collection(db, path),
      where("department", "==", departmentId)
    );
    return await new Promise<Unsubscribe>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        $query,
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getByDivision(divisionId: string) {
    const path = this.getPath();
    if (!path) return;
    this.store.user.removeAll();

    const $query = query(collection(db, path), where("division", "==", divisionId));

    return await new Promise<Unsubscribe>((resolve, reject) => {
      const unsubscribe = onSnapshot(
        $query,
        (querySnapshot) => {
          const items: IUser[] = [];
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() } as IUser);
          });

          this.store.user.load(items);
          resolve(unsubscribe);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async getByUid(uid: string) {
    const path = this.getPath();
    if (!path) return;

    const unsubscribe = onSnapshot(doc(db, path, uid), (doc) => {
      if (!doc.exists) return;
      const item = { uid: doc.id, ...doc.data() } as IUser;

      this.store.user.load([item]);
    });
    return unsubscribe;
  }

  async doesDepartmentHasusers(id: string) {
    const path = this.getPath();
    if (!path) return;

    const $query = query(collection(db, path), where("departmentId", "==", id)
    );
    const querySnapshot = await getDocs($query);
    const users = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), uid: doc.id } as IUser;
    });
    return users.length !== 0 ? true : false;
  }

  async doesRegionHasusers(id: string) {
    const path = this.getPath();
    if (!path) return;

    const $query = query(collection(db, path), where("regionId", "==", id));
    const querySnapshot = await getDocs($query);
    const users = querySnapshot.docs.map((doc) => {
      return { ...doc.data(), uid: doc.id } as IUser;
    });
    return users.length !== 0 ? true : false;
  }

  // async deleteFromAuth(user: User) {
  //   await deleteUser(user);
  //   await this.api.user.delete(user);
  //   return;
  // }
}
