import { observer } from "mobx-react-lite";
import { FormEvent, useMemo, useState } from "react";
import { IOption } from "../../../shared/components/single-select/SingleSelect";
import { useAppContext } from "../../../shared/functions/Context";
import { hideModalFromId } from "../../../shared/functions/ModalShow";
import MODAL_NAMES from "../ModalName";
import { IMilestoneIssue, IMilestoneIssueSeverity, IMilestoneIssueStatus, defaultIssue } from "../../../shared/models/MilestoneIssue";

interface Iprops {
  projectId: string;
}

const MilestoneIssueModal = observer((props: Iprops) => {

  const { projectId } = props;
  const { api, store } = useAppContext();
  const me = store.auth.meJson;

  const milestoneId = store.projectTask.selectedMID;
  const tasksOptions = store.projectTask.all.map(t => t.asJson).filter(t => t.milestoneId === milestoneId).map(issue => ({ value: issue.id, label: issue.taskName }));

  const options: IOption[] = useMemo(() =>
    store.user.all.map((user) => {
      return {
        label: user.asJson.displayName || "",
        value: user.asJson.uid
      };
    }).filter(user => user.value !== me?.uid), [store.user]
  );

  const [issue, setIssue] = useState<IMilestoneIssue>(defaultIssue);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!me) return;
    const _issue: IMilestoneIssue = {
      ...issue,
      usersId: [me.uid],
      milestoneId: milestoneId,
      projectId: projectId,
    }
    await create(_issue);
    setLoading(false);
    onCancel();
  };

  const create = async (issue: IMilestoneIssue) => {
    try {
      await api.milestoneIssue.createIssue(issue)
    } catch (error) { }
  };

  const onCancel = () => {
    setIssue(defaultIssue)
    hideModalFromId(MODAL_NAMES.PROJECTS.MILESTONE_ISSUE_MODAL);
  };

  return (
    <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <button
        className="uk-modal-close-default"
        type="button"
        data-uk-close
      ></button>
      <h3 className="uk-modal-title">Milestone Issue</h3>
      <div className="dialog-content uk-position-relative">
        <form onSubmit={handleSubmit}>
          <fieldset className="uk-fieldset">
            <div className="uk-margin">
              <input className="uk-input" required type="text" placeholder="Issue"
                onChange={(e) => setIssue({ ...issue, issueName: e.target.value })} />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="severity">Severity/Impact</label>
              <select id="severity" className="uk-select" name="severity"
                onChange={(e) => setIssue({ ...issue, severity: e.target.value as IMilestoneIssueSeverity })}>
                <option value={"low"}>Low</option>
                <option value={"medium"}>Medium</option>
                <option value={"high"}>High</option>
              </select>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="level">Status</label>
              <select id="level" className="uk-select" name="level"
                onChange={(e) => setIssue({ ...issue, status: e.target.value as IMilestoneIssueStatus })}>
                <option value={"potential"}>Potential</option>
                <option value={"identified"}>Identified</option>
                <option value={"resolved"}>Resolved</option>
              </select>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="logdate">Log Date</label>
              <input id="logdate" className="uk-input" type="date" placeholder="Risk Log Date"
                onChange={(e) => setIssue({ ...issue, logDate: e.target.value })} />
            </div>
            <div className="uk-margin">
              <label className="uk-form-label" htmlFor="resDate">Resolution Date</label>
              <input id="resDate" className="uk-input" type="date" placeholder="Risk Resolution Date"
                onChange={(e) => setIssue({ ...issue, resolutionDate: e.target.value })} />
            </div>
            <div className="uk-margin">
              <textarea className="uk-textarea" rows={2} placeholder="Description"
                required onChange={(e) => setIssue({ ...issue, description: e.target.value })}></textarea>
            </div>
          </fieldset>
          <div className="uk-width-1-1 uk-text-right">
            <button
              className="btn-text uk-margin-right"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={loading}
            >
              Save {loading && <div data-uk-spinner="ratio: .5"></div>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default MilestoneIssueModal;
