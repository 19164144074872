// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// pombili, nadia, ruben, abisai

const firebaseConfig = {
  apiKey: "AIzaSyBfiuP438Y0bVJhkQYiwUqqzGYl8y-tFzE",
  authDomain: "projects-module.firebaseapp.com",
  projectId: "projects-module",
  storageBucket: "projects-module.appspot.com",
  messagingSenderId: "800220488552",
  appId: "1:800220488552:web:fa017127bc5f4f03466cdd",
  measurementId: "G-W9VPXWQ70S"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const appAuthWorker = initializeApp(firebaseConfig, "authWorker");

export const auth = getAuth(app);
export const authWorker = getAuth(appAuthWorker);

export const analytics = getAnalytics(app);
export const db = getFirestore(app);


export const storage = getStorage(app);




// apiKey: "AIzaSyC38O17gs6y6BwnmgWZgR_03L4DRpuQoXI",
// authDomain: "allmanaged.firebaseapp.com",
// projectId: "allmanaged",
// storageBucket: "allmanaged.appspot.com",
// messagingSenderId: "302821722124",
// appId: "1:302821722124:web:18a5a8cd480a75a5a44629",
// measurementId: "G-9M7BX79N6C"


  // apiKey: "AIzaSyBfiuP438Y0bVJhkQYiwUqqzGYl8y-tFzE",
  // authDomain: "projects-module.firebaseapp.com",
  // projectId: "projects-module",
  // storageBucket: "projects-module.appspot.com",
  // messagingSenderId: "800220488552",
  // appId: "1:800220488552:web:fa017127bc5f4f03466cdd",
  // measurementId: "G-W9VPXWQ70S"