import AuthStore from "./AuthStore";
import BusinessUnitStore from "./BusinessUnitStore";
import DepartmentStore from "./DepartmentStore";
import UploadManagerStore from "./UploadManagerStore";
import UserStore from "./UserStore";
import { MainApp } from "../models/App";
import PortfolioStore from "./PortfolioStore";
import ProjectManagementStore from "./ProjectManagementStore";
import ProjectRiskStore from "./ProjectRiskStore";
import ProjectTaskStore from "./ProjectTaskStore";
import ProjectStatusStore from "./ProjectStatusStore";
import FeedbackStore from "./FeedbackStore";
import ProjectLogsStore from "./ProjectLogsStore";
import GeneralTaskStore from "./GeneralTaskStore";
import MilestoneIssue from "../models/MilestoneIssue";
import MilestoneIssueStore from "./MilestoneIssueStore";

export default class AppStore {
  app: MainApp;

  auth = new AuthStore(this);
  user = new UserStore(this);




  department = new DepartmentStore(this);
  businessUnit = new BusinessUnitStore(this);

  // upload manager
  uploadManager = new UploadManagerStore(this);

  // project Management Stores
  portfolio = new PortfolioStore(this);
  projectManagement = new ProjectManagementStore(this);
  projectTask = new ProjectTaskStore(this);
  projectRisk = new ProjectRiskStore(this);
  projectStatus = new ProjectStatusStore();
  projectLogs = new ProjectLogsStore(this);
  generalTask = new GeneralTaskStore(this)

  // feedback Store
  feedback = new FeedbackStore(this);
  milestoneIssue = new MilestoneIssueStore(this);

  constructor(app: MainApp) {
    this.app = app;
  }
}
