import AppStore from "../stores/AppStore";
import MailApi from "./MailApi";
import { mailApiEndpoint } from "../config/mail-api-config";
import BusinessUnitApi from "./BusinessUnitApi";
import DepartmentApi from "./DepartmentApi";
import AuthApi from "./AuthApi";
import UserApi from "./UserApi";
import UploadManagerApi from "./UploadManagerApi";

import ProjectManagementApi from "./ProjectManagementApi";
import FeedbackApi from "./FeedbackApi";
import GeneralTask from "../models/GeneralTasks";
import GeneralTaskApi from "./GeneralTaskApi";
import MilestoneIssueApi from "./MilestoneIssueApi";

export const apiPathCompanyLevel = (
  category:
    | "projects"
    | "portfolios"
    | "scorecards"
    | "businessUnits"
    | "divisions"
    | "departments"
    | "users"
    | "folders"
    | "folderFiles"
): string => {
  return category;
};

export const apiPathScorecardLevel = (
  scorecardId: string,
  category:
    | "scorecardMetadata"
    | "scorecardDraftReviews"
    | "scorecardQuarter1Reviews"
    | "scorecardQuarter2Reviews"
    | "scorecardQuarter3Reviews"
    | "scorecardQuarter4Reviews"
    | "measures"
    | "measuresAudit"
    | "objectives"
    | "strategicThemes"
    | "companyObjectives"
    | "companyMeasuresAudit"
    | "companyMeasures"
    | "departmentObjectives"
    | "departmentMeasures"
    | "departmentMeasuresAudit"
    | "divisionObjectives"
    | "divisionMeasures"
    | "divisionMeasuresAudit"
): string => {
  return `${apiPathCompanyLevel("scorecards")}/${scorecardId}/${category}`;
};


export const apiPathProjectLevel = (
  projectId: string,
  category: | "tasks" | "risks" | "logs" | "issues"
): string => {
  return `${"projects"}/${projectId}/${category}`;
};

export default class AppApi {
  mail: MailApi;
  auth: AuthApi;
  user: UserApi;

  department: DepartmentApi;
  businessUnit: BusinessUnitApi;

  uploadManager: UploadManagerApi;
  projectManagement: ProjectManagementApi;
  feedback: FeedbackApi;
  generalTask: GeneralTaskApi;

  milestoneIssue: MilestoneIssueApi;

  constructor(store: AppStore) {
    this.mail = new MailApi(this, store, mailApiEndpoint);
    this.auth = new AuthApi(this, store);
    this.user = new UserApi(this, store);
    
    // Company Level Paths
    this.department = new DepartmentApi(this, store);
    this.businessUnit = new BusinessUnitApi(this, store);


    // File Upload Manager
    this.uploadManager = new UploadManagerApi(this, store);


    // Project Managements APIs
    this.projectManagement = new ProjectManagementApi(this, store);
    // Feedback APIs
    this.feedback = new FeedbackApi(this, store);

    this.generalTask = new GeneralTaskApi(this, store);
    this.milestoneIssue = new MilestoneIssueApi(this, store);
  }
}
