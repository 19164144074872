import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

// Measure performance
reportWebVitals();
