import Store from "./Store";
import AppStore from "./AppStore";
import { runInAction, toJS } from "mobx";
import { sortAlphabetically } from "../../logged-in/shared/utils/utils";
import MilestoneIssue, { IMilestoneIssue } from "../models/MilestoneIssue";

export default class MilestoneIssueStore extends Store<IMilestoneIssue, MilestoneIssue> {
  items = new Map<string, MilestoneIssue>();

  constructor(store: AppStore) {
    super(store);
    this.store = store;
  }

  load(items: IMilestoneIssue[] = []) {
    runInAction(() => {
      items.forEach((item) => {
        this.items.set(item.id, new MilestoneIssue(this.store, item));
      });
    });
  }


  getByUid(uid: string) {
    const all = Array.from(this.items.values());
    return all
      .filter((item) => item.asJson.usersId[0] === uid)
      .sort((a, b) =>
        sortAlphabetically(a.asJson.description, b.asJson.description)
      );
  }

  getByDate(date: string) {
    const all = Array.from(this.items.values());
    const measures = all
      .filter((item) => item.asJson.logDate === date)
      .sort((a, b) =>
        sortAlphabetically(a.asJson.description, b.asJson.description)
      );
    return measures;
  }

  get all() {
    return Array.from(toJS(this.items.values())).sort((a, b) =>
      sortAlphabetically(a.asJson.description, b.asJson.description)
    );
  }
}
