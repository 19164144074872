import { makeAutoObservable, toJS } from "mobx";
import AppStore from "../stores/AppStore";

export type IMilestoneIssueStatus = "potential" | "identified" | "resolved"

export type IMilestoneIssueSeverity = "low" | "medium" | "high"

export const defaultIssue: IMilestoneIssue = {
    id: "",
    issueName: "",
    description: "",
    milestoneId: "",
    projectId: "",
    status: "identified",
    logDate: "",
    resolutionDate: "",
    usersId: [],
    severity: "low"
}

export interface IMilestoneIssue {
    id: string;
    issueName: string;
    description: string;
    milestoneId: string;
    projectId: string
    status: IMilestoneIssueStatus;
    logDate: string;
    resolutionDate: string;
    severity: IMilestoneIssueSeverity;
    color?: string;
    comments?: string[];
    dependencies?: string[],
    files?: File[];
    usersId: string[];
}

export interface File {
    name: string;
    link: string;
}

export default class MilestoneIssue {
    private task: IMilestoneIssue;

    constructor(private store: AppStore, task: IMilestoneIssue) {
        makeAutoObservable(this);
        this.task = task;
    }

    get asJson(): IMilestoneIssue {
        return toJS(this.task);
    }
}